<template>
  <div class="content_block">
    <div class="top">
      <b>企业联系人</b>
      <template v-if="!isDisabled">
        <div class="btnContainer" v-if="isEditing">
          <el-button type="default" size="mini"  @click="handleCancel" v-if="!isShowHead">取消</el-button>
          <el-button type="primary" size="mini" @click="handleSave" v-if="!isShowHead">保存</el-button>
          <el-button type="primary" size="mini" @click="handleUpdate" v-else>保存</el-button>
        </div>
        <div class="btnContainer" v-else>
          <el-button type="primary" plain icon="el-icon-edit" size="mini" @click="handleEdit">编辑</el-button>
        </div>
      </template>
    </div>
    <el-form label-position="right" v-if="isEditing" style="margin-top: 10px;" :rules="rules" :model="form" ref="JZForm" label-width="100px">
      <el-row >
        <el-col :span="12" >
          <el-form-item label="姓名" prop="contactUserName" label-width="90px">
            <el-input v-model="form.contactUserName" placeholder="请输入姓名"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" >
          <el-form-item label="电话" prop="contactUserPhone">
            <el-input v-model="form.contactUserPhone" placeholder="请输入电话" maxlength="11" show-word-limit></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12" >
          <el-form-item label="邮箱"  label-width="90px" prop="contactUserEmail">
            <el-input v-model="form.contactUserEmail" placeholder="请输入邮箱"></el-input>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <el-descriptions :colon="false" :column="2" v-else style="margin-top: 10px;flex:1;">
      <el-descriptions-item label="姓名" labelClassName="warningLabel" contentClassName="warningContent">{{ form.contactUserName }}</el-descriptions-item>
      <el-descriptions-item label="电话" labelClassName="warningLabel" contentClassName="warningContent">{{ form.contactUserPhone }}</el-descriptions-item>
      <el-descriptions-item label="邮箱" labelClassName="warningLabel" contentClassName="warningContent">{{ form.contactUserEmail }}</el-descriptions-item>
    </el-descriptions>
  </div>
</template>

<script>
export default {
  name: '',
  props: {
    concatLists: {
      type: Array,
      default () {
        return [];
      },
    },
    type: {
      type: String,
      default: '1',
    },
    isDisabled: {
      type: Boolean,
      default () {
        return false;
      },
    },
    isShowHead: {
      type: Boolean,
      default () {
        return false;
      },
    },
    activeName: {
      type: String,
      default: '',
    },
  },
  watch: {
    activeName: {
      handler (val) {
        if (val === 'CorporateContacts') {
          let info = this.concatLists.find(i=>i.contactType === this.type);
          info = info ? JSON.parse(JSON.stringify(info)) : null;
          this.form = info ? {
            ...info,
            operateType: '2',
            contactType: this.type,
          } : {
            contactUserPhone: '',
            contactUserEmail: '',
            contactUserName: '',
            operateType: '1',
            contactType: this.type,
          };
          this.clearValidate();
        }
      },
      immediate: true,
      deep: true,
    },
    isShowHead: {
      handler (val) {
        if (val) {
          this.isEditing = this.isShowHead;
        }
      },
      immediate: true,
      deep: true,
    },
    concatLists: {
      handler (val) {
        if (val && val.length) {
          let info = val.find(i=>i.contactType === this.type);
          info = info ? JSON.parse(JSON.stringify(info)) : null;
          this.form = info ? {
            ...info,
            operateType: '2',
            contactType: this.type,
          } : {
            contactUserPhone: '',
            contactUserEmail: '',
            contactUserName: '',
            operateType: '1',
            contactType: this.type,
          };
          this.clearValidate();
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    clearValidate () {
      this.$nextTick(()=>{
        this.$refs.JZForm && this.$refs.JZForm.clearValidate();
      });
    },
    handleEdit () {
      this.isEditing = true;
    },
    handleCancel () {
      let info = this.concatLists.find(i=>i.contactType === this.type);
      info = info ? JSON.parse(JSON.stringify(info)) : null;
      this.form = info ? {
        ...info,
        operateType: '2',
        contactType: this.type,
      } : {
        contactUserPhone: '',
        contactUserEmail: '',
        contactUserName: '',
        operateType: '1',
        contactType: this.type,
      };
      this.isEditing = false;
    },
    handleSave () {
      this.$refs.JZForm.validate(valid=>{
        if (valid) {
          let tenantFileReqDTOs = (this.form.tenantFileReqDTOs || []).map(i=>({
            ...i,
            contactType: this.type,
          }));
          this.$emit('updateContact', {...this.form, tenantFileReqDTOs, contactType: '1'}, this.type);
          this.isEditing = false;
        }
      });
    },
    handleUpdate () {
      this.$refs.JZForm.validate(valid=>{
        if (valid) {
          let tenantFileReqDTOs = (this.form.tenantFileReqDTOs || []).map(i=>({
            ...i,
            contactType: this.type,
          }));
          this.$emit('updateContact', {...this.form, tenantFileReqDTOs, contactType: '1'}, this.type);
        }
      });
    },
  },
  data () {
    return {
      isEditing: false,
      form: {
        contactUserPhone: '',
        contactUserEmail: '',
        contactUserName: '',
        operateType: '1',
        contactType: this.type,
      },
      rules: {
        contactUserName: [
          {
            required: true,
            message: '请输入姓名',
            trigger: ['blur', 'change'],
          },
        ],
        contactUserPhone: [
          {
            required: true,
            message: '请输入电话',
            trigger: ['blur', 'change'],
          },
          {
            pattern: /^1\d{10}$/,
            message: '电话格式不正确',
            trigger: ['blur', 'change'],
          },
        ],
        contactUserEmail: [
          {
            pattern: /^(?=.*@)(?=.*\.)[^@.].*[^@.]$/,
            message: '请输入合法的邮箱',
            trigger: ['blur'],
          },
        ],
      },
    };
  },
};
</script>
<style scoped lang='scss'>
/* @import url(); 引入css类 */
.content_block{
  padding: 0 20px 20px 20px;
  min-height: calc(100vh - 420px);
  .top{
    height: 48px;
    line-height: 48px;
    display: flex;
    .btnContainer{
      flex: 1;
      text-align:right;
    }
  }
}
/deep/ .warningLabel{
  width: 80px;
  text-align: right;
  display: inline-block;
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #929AA6;
  letter-spacing: 0;
  text-align: right;
  line-height: 32px;
}
/deep/ .warningContent{
  font-family: PingFangSC-Regular;
  font-size: 14px;
  color: #1F2733;
  letter-spacing: 0;
  text-align: left;
  line-height: 32px;
  display: flex;
}
.infoContainer{
  display: flex;
  background: #FFFFFF;
  border: 1px solid #DFE2E8;
  border-radius: 4px;
  padding: 7px 20px;
  margin-bottom: 10px;
  div{
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.infoFormContainer{
  display: flex;
  background: #FFFFFF;
  border: 1px solid #DFE2E8;
  border-radius: 4px;
  padding: 7px 20px;
  margin-bottom: 10px;
  .left{
    flex: 1;
    margin-right: 10px;
  }
  .right{
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
